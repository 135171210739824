import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { StytchProvider } from '@stytch/react';
import { StytchUIClient } from '@stytch/vanilla-js';
import App from './App';
import { ColorModeScript } from '@chakra-ui/react';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import TokenAuthenticator from './components/TokenAuthenticator';

const stytch = new StytchUIClient('public-token-live-585f92b7-a129-4396-9168-4ca3fbc2f07a');

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <ColorModeScript />
    <StytchProvider stytch={stytch}>
      <Router>
        <TokenAuthenticator>
          <App />
        </TokenAuthenticator>
      </Router>
    </StytchProvider>
  </React.StrictMode>
);
