// src/App.js
import React, { useEffect, useState, Suspense } from 'react';
import { ChakraProvider, Box, VStack } from '@chakra-ui/react';
import { useRoutes, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import BottomBar from './components/BottomBar';
import FooterBar from './components/FooterBar';
import routes from './routes';
import theme from './theme';
import { initGA, logPageView } from './analytics';

function App() {
  const [currentStep, setCurrentStep] = useState(0);
  const [images, setImages] = useState([]);
  const [analysisData, setAnalysisData] = useState(null);
  const location = useLocation();

  useEffect(() => {
    initGA();
    logPageView(location.pathname);
  }, []);

  useEffect(() => {
    logPageView(location.pathname);
  }, [location.pathname]);

  const routing = useRoutes(routes({ currentStep, setCurrentStep, images, setImages, analysisData, setAnalysisData }));

  const handleReset = () => {
    setCurrentStep(0);
    setImages([]);
    setAnalysisData(null);
  };

  const isSpecialRoute = ['/auth', '/authenticate', '/privacy-policy', '/terms-and-conditions'].includes(location.pathname);

  return (
    <ChakraProvider theme={theme}>
      <Box position="fixed" top="0" width="100%" zIndex="1000" bg="white" borderBottomWidth="1px">
        <Navbar />
      </Box>
      <Box pt={isSpecialRoute ? 0 : 20} maxW="1200px" mx="auto" minHeight="100vh">
        <Suspense fallback={<div>Loading...</div>}>
          {routing}
        </Suspense>
      </Box>
      {isSpecialRoute ? (
        <Box width="100%" bg="white" borderTopWidth="1px" height="40px" position="fixed" bottom="0">
          <FooterBar />
        </Box>
      ) : (
        <Box position="fixed" bottom="0" width="100%" zIndex="1000">
          <VStack spacing={0}>
            <Box width="100%" bg="white" py={4} boxShadow="md" borderTopWidth="1px" borderColor="gray.200">
              <BottomBar
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                images={images}
                handleReset={handleReset}
                setAnalysisData={setAnalysisData}
              />
            </Box>
            <Box width="100%" bg="white" borderTopWidth="1px" height="40px">
              <FooterBar />
            </Box>
          </VStack>
        </Box>
      )}
    </ChakraProvider>
  );
}

export default App;
