import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  colors: {
    primary: {
      50: '#eae2ff',
      100: '#cbb6ff',
      200: '#ac88ff',
      300: '#8e5aff',
      400: '#6f2cff',
      500: '#6D24E2', // Your primary color
      600: '#4501b4',
      700: '#330182',
      800: '#220051',
      900: '#110021',
    },
    secondary: {
      50: '#e2e8f0',
      100: '#cbd5e1',
      200: '#a0aec0',
      300: '#718096',
      400: '#4a5568',
      500: '#2d3748',
      600: '#1a202c',
      700: '#1a202c',
      800: '#1a202c',
      900: '#1a202c',
    },
    gray: {
      50: '#f7f7f8',
      100: '#e8e8ed',
      200: '#d1d1db',
      300: '#a9a9bc',
      500: '#6c6c89',
      700: '#3f3f50',
    },
    black: {
      500: '#121217',
    },
  },
  fonts: {
    heading: 'Inter, sans-serif',
    body: 'Inter, sans-serif',
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: 'bold',
        borderRadius: 'full', // Fully rounded buttons
      },
      variants: {
        solid: {
          bg: 'primary.500',
          color: 'white',
          _hover: {
            bg: 'primary.600',
          },
        },
        outline: {
          borderColor: 'primary.500',
          color: 'primary.500',
          _hover: {
            bg: 'primary.50',
          },
        },
      },
    },
  },
  styles: {
    global: {
      'html, body': {
        color: 'gray.800',
        lineHeight: 'tall',
        backgroundColor: 'hsla(261,100%,86%,0.2)',
        backgroundImage: `
          radial-gradient(at 0% 100%, hsla(247,100%,69%,0.3) 0px, transparent 50%),
          radial-gradient(at 100% 100%, hsla(253,100%,65%,1) 0px, transparent 50%),
          radial-gradient(at 51% 0%, hsla(136,0%,100%,1) 0px, transparent 50%)`,
        backgroundSize: 'cover',
        minHeight: '100vh', // Ensure the body takes at least the full height of the viewport
      },
      a: {
        color: 'primary.500',
        _hover: {
          color: 'primary.600',
        },
      },
    },
  },
});

export default theme;
