// src/components/Navbar.js
import React from 'react';
import { Box, Button, Flex, Link, Spacer, Stack, useBreakpointValue } from '@chakra-ui/react';
import { useStytchUser, useStytch } from '@stytch/react';

const Navbar = () => {
  const { user } = useStytchUser();
  const stytch = useStytch();
  const isMobile = useBreakpointValue({ base: true, md: false });

  const handleLogout = async () => {
    if (user) {
      try {
        await stytch.session.revoke();
        window.location.href = '/';
      } catch (error) {
        console.error('Error logging out:', error);
      }
    }
  };

  return (
    <Box as="nav" w="100%" py={4} borderBottom="1px" borderColor="gray.200">
      <Box maxW="1200px" mx="auto" px={4}>
        <Flex align="center" justify="space-between">
          <Flex align="center">
            <Box as="a" href="/" fontSize="lg" fontWeight="bold" mr={8}>
              UXO Vision
            </Box>
            {!isMobile && (
              <Stack direction="row" spacing={4}>
                <Link href="/">Home</Link>
                <Link target='_blank' href="https://weareindy.com/app/shared/forms/6677347105ad84954b4cf32b/VCjcSANd66BsX0DgqtnqqmU9DU7ne7F0x2D4IOnjksxR11Az2DECouACLy5OCYcE/">Contact us</Link>
                {user && <Link href="/profile">Profile</Link>}
              </Stack>
            )}
          </Flex>
          <Spacer />
          <Stack direction="row" spacing={4}>
            {user ? (
              <Button variant="outline" colorScheme="brand" onClick={handleLogout}>
                Logout
              </Button>
            ) : (
              <Link href="/auth">
                <Button colorScheme="brand">Login</Button>
              </Link>
            )}
          </Stack>
        </Flex>
      </Box>
    </Box>
  );
};

export default Navbar;
