// src/analytics.js
export const initGA = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() { window.dataLayer.push(arguments); }
    gtag('js', new Date());
    gtag('config', 'G-ERPT1J3051');
  };
  
  export const logPageView = (url) => {
    window.gtag('config', 'G-ERPT1J3051', {
      page_path: url,
    });
  };
  
  export const logEvent = (action, category, label, value) => {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value: value,
    });
  };
  