// src/components/TokenAuthenticator.js
import { useStytch, useStytchUser } from '@stytch/react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const TokenAuthenticator = ({ children }) => {
  const stytch = useStytch();
  const { user } = useStytchUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (stytch && !user) {
      const queryParams = new URLSearchParams(window.location.search);
      const token = queryParams.get("token");
      const tokenType = queryParams.get("stytch_token_type");
      
      console.log("Token: ", token);
      console.log("Token Type: ", tokenType);
  
      if (token && tokenType) {
        if (tokenType === "magic_links") {
          stytch.magicLinks.authenticate(token, {
            session_duration_minutes: 60,
          }).then((response) => {
            console.log("Authentication successful: ", response);
            const userId = response.user_id;
            sessionStorage.setItem('user_id', userId); // Store user ID in session storage
            navigate('/app');
          }).catch((error) => {
            console.error('Error authenticating magic link:', error);
          });
        } else if (tokenType === "oauth") {
          stytch.oauth.authenticate(token, {
            session_duration_minutes: 60,
          }).then((response) => {
            console.log("OAuth authentication successful: ", response);
            const userId = response.user_id;
            sessionStorage.setItem('user_id', userId); // Store user ID in session storage
            navigate('/app');
          }).catch((error) => {
            console.error('Error authenticating OAuth token:', error);
          });
        }
      }
    }
  }, [stytch, user, navigate]);

  return children;
};

export default TokenAuthenticator;
