// src/components/SurveyOverlay.js
import React, { useEffect, useRef } from 'react';
import { Box, Flex, Heading, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, CloseButton } from '@chakra-ui/react';

const SurveyOverlay = ({ onClose }) => {
  const cancelRef = useRef();
  const containerRef = useRef();

  useEffect(() => {
    const existingScript = document.getElementById('indy-survey-script');
    const existingDiv = document.getElementById('indy-666f3e1c4229f56368e160d6');

    if (existingScript || existingDiv) {
      if (containerRef.current && existingDiv) {
        containerRef.current.appendChild(existingDiv);
      }
      return;
    }

    const script = document.createElement('script');
    script.id = 'indy-survey-script';
    script.innerHTML = `
      (function(a,s,n,i,p,e,t) {
        a.INDY=a.INDY || {};
        a.INDY.baseUrl=i;
        a.INDY.id='666f3e1c4229f56368e160d6';
        a.INDY.accessId='u6vlHwKayMX77gBV3h3ntKx4mOPqfOBEqF1fBo7Qaz2EQUZkkuCqA2cMIGu41MuW';
        a.INDY.htb='true';
        e=s.createElement(n);
        e.type="text/javascript";
        e.async=!0;
        e.src=i+p;
        t=s.getElementsByTagName(n)[0];
        t.parentNode.insertBefore(e,t);
      })(window,document,"script","https://weareindy.com/app","/static/js/forms-embed.js");
    `;
    document.body.appendChild(script);
  }, []);

  return (
    <AlertDialog
      isOpen={true}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isCentered
      size="xl"
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader display="flex" justifyContent="space-between" alignItems="center">
            <Heading as="h3" size="lg">We'd love to hear from you!</Heading>
            <CloseButton onClick={onClose} />
          </AlertDialogHeader>
          <AlertDialogBody>
            <Box
              id="indy-666f3e1c4229f56368e160d6"
              ref={containerRef}
              maxHeight="70vh"
              overflow="auto"
            />
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default SurveyOverlay;
