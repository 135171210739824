import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { useStytchUser } from '@stytch/react';

const PrivateRoute = ({ element: Component, ...rest }) => {
  const { user } = useStytchUser();
  return user ? <Component {...rest} /> : <Navigate to="/auth" />;
};

export default PrivateRoute;
