// src/components/SurveyFloatingTrigger.js
import React, { useState } from 'react';
import { Box, Flex, Circle, Heading, useColorModeValue } from '@chakra-ui/react';
import { ChatIcon, StarIcon } from '@chakra-ui/icons';
import SurveyOverlay from './SurveyOverlay';

const SurveyFloatingTrigger = () => {
  const primaryColor = useColorModeValue('primary.500', 'primary.200');
  const bgColor = useColorModeValue('white', 'gray.800');
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);

  const handleClick = () => {
    setIsOverlayOpen(true);
  };

  const handleClose = () => {
    setIsOverlayOpen(false);
  };

  return (
    <>
      <Box
        position="fixed"
        bottom="136px"
        right="20px"
        bg={bgColor}
        p={4}
        borderRadius="md"
        boxShadow="md"
        zIndex="1000"
        cursor="pointer"
        onClick={handleClick}
      >
        <Flex alignItems="center">
          <Circle size="32px" bg="primary.50" color={primaryColor} mr={2}>
            <StarIcon />
          </Circle>
          <Heading as="h3" size="sm">
            Tell us how it did!
          </Heading>
        </Flex>
      </Box>
      {isOverlayOpen && <SurveyOverlay onClose={handleClose} />}
    </>
  );
};

export default SurveyFloatingTrigger;
