// routes.js
import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import { useStytchUser } from '@stytch/react';

const ImageUploader = lazy(() => import('./components/ImageUploader'));
const NextStep = lazy(() => import('./components/NextStep'));
const Login = lazy(() => import('./components/Login'));
const Profile = lazy(() => import('./components/Profile'));
const PrivacyPolicy = lazy(() => import('./components/PrivacyPolicy'));
const TermsAndConditions = lazy(() => import('./components/TermsAndConditions'));

const useAuth = () => {
  const { user } = useStytchUser();
  return { user };
};

const AppRoutes = ({ currentStep, setCurrentStep, images, setImages, analysisData, setAnalysisData }) => (
  <>
    {currentStep === 0 && <ImageUploader images={images} setImages={setImages} />}
    {currentStep === 1 && <ImageUploader images={images} setImages={setImages} />}
    {currentStep === 2 && <NextStep images={images} analysisData={analysisData} />}
  </>
);

const routes = ({ currentStep, setCurrentStep, images, setImages, analysisData, setAnalysisData }) => [
  {
    path: '/',
    element: <Navigate to="/app" />,
  },
  {
    path: '/profile',
    element: <PrivateRoute element={Profile} />,
  },
  {
    path: '/auth',
    element: <Login />,
  },
  {
    path: '/authenticate',
    element: <Login />,
  },
  {
    path: '/app',
    element: (
      <PrivateRoute
        element={() => (
          <AppRoutes
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            images={images}
            setImages={setImages}
            analysisData={analysisData}
            setAnalysisData={setAnalysisData}
          />
        )}
      />
    ),
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy />,
  },
  {
    path: '/terms-and-conditions',
    element: <TermsAndConditions />,
  },
];

export default routes;
