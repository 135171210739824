// src/components/BottomBar.js
import React, { useState, useEffect } from 'react';
import { Box, Button, Flex, IconButton, useDisclosure, Text } from '@chakra-ui/react';
import { ArrowBackIcon, RepeatIcon, ArrowForwardIcon, CheckIcon } from '@chakra-ui/icons';
import { AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay } from '@chakra-ui/react';
import axios from 'axios';
import loadingPhrases from './loadingPhrases.json';
import SurveyFloatingTrigger from './SurveyFloatingTrigger'; // Import SurveyFloatingTrigger
import '../styles.css'; // Ensure the correct path for the CSS

const BottomBar = ({ currentStep, setCurrentStep, images, handleReset, setAnalysisData }) => {
  const { isOpen: isResetOpen, onOpen: onResetOpen, onClose: onResetClose } = useDisclosure();
  const { isOpen: isGoBackOpen, onOpen: onGoBackOpen, onClose: onGoBackClose } = useDisclosure();
  const cancelRef = React.useRef();
  const [loading, setLoading] = useState(false);
  const [analysisComplete, setAnalysisComplete] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');

  const onConfirmReset = () => {
    handleReset();
    onResetClose();
    setAnalysisComplete(false);
  };

  const onConfirmGoBack = () => {
    handleReset();
    setCurrentStep(0);
    onGoBackClose();
    setAnalysisComplete(false);
  };

  useEffect(() => {
    if (loading) {
      const intervalId = setInterval(() => {
        setLoadingMessage(loadingPhrases[Math.floor(Math.random() * loadingPhrases.length)]);
      }, 5000);
      return () => clearInterval(intervalId);
    }
  }, [loading]);

  const handleAnalyze = async () => {
    setLoading(true);
    try {
      const response = await axios.post('/analyze', { imageUrls: images });
      const analysisData = response.data;

      setAnalysisData(analysisData);
      setAnalysisComplete(true);
      setCurrentStep(2);  // Redirect to the results page
    } catch (error) {
      console.error('Error analyzing images:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (images.length > 0) {
      setAnalysisComplete(false);
    }
  }, [images]);

  const getUploadMessage = () => {
    const uploadedCount = images.length;
    const remainingImages = 3 - uploadedCount;

    return (
      <Text color="gray.500" textAlign="center" fontSize="md" maxW="100%">
        {uploadedCount === 0
          ? 'Upload at least 1 image to get started'
          : uploadedCount === 1
          ? <span><span style={{ color: '#121217' }}>1 image</span> uploaded. You can select up to <span style={{ color: '#121217' }}>2 more images</span>.</span>
          : uploadedCount === 2
          ? <span><span style={{ color: '#121217' }}>2 images</span> uploaded. You can select up to <span style={{ color: '#121217' }}>1 more image</span>.</span>
          : <span><span style={{ color: '#121217' }}>3 images</span> uploaded. Maximum number of images reached.</span>
        }
      </Text>
    );
  };

  return (
    <>
      <Box maxW="1200px" mx="auto" px={4} position="relative">
        <Flex justify="space-between" align="center" position="relative">
          {currentStep === 2 ? (
            <Button
              onClick={onGoBackOpen}
              leftIcon={<ArrowBackIcon />}
              variant="outline"
            >
              Go Back
            </Button>
          ) : (
            <Box width="125px"></Box>
          )}
          <Flex align="center" justify="center" flex="1" position="absolute" left="50%" transform="translateX(-50%)">
            {loading ? (
              <Text mx={4} fontSize="md" color="gray.600" className="fade-in-out">
                {loadingMessage}
              </Text>
            ) : analysisComplete ? (
              <Text mx={4} fontSize="md" color="green.500">
                Analysis Complete <CheckIcon ml={2} />
              </Text>
            ) : (
              <Box maxW="100%">
                {getUploadMessage()}
              </Box>
            )}
          </Flex>
          <Flex>
            <IconButton
              icon={<RepeatIcon />}
              onClick={onResetOpen}
              mr={4}
              aria-label="Reset"
              variant="outline"
            />
            {images.length > 0 && currentStep !== 2 && (
              <Button
                onClick={handleAnalyze}
                isLoading={loading}
                rightIcon={<ArrowForwardIcon />}
                isDisabled={loading || analysisComplete}
                variant="solid"
              >
                {loading ? 'Analyzing...' : 'Analyze'}
              </Button>
            )}
          </Flex>
        </Flex>
      </Box>

      <AlertDialog
        isOpen={isResetOpen}
        leastDestructiveRef={cancelRef}
        onClose={onResetClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirm Reset
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to reset? All your progress will be lost.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onResetClose} variant="outline">
                Cancel
              </Button>
              <Button colorScheme="red" onClick={onConfirmReset} ml={3}>
                Reset
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <AlertDialog
        isOpen={isGoBackOpen}
        leastDestructiveRef={cancelRef}
        onClose={onGoBackClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirm Go Back
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to go back? All your progress will be lost.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onGoBackClose} variant="outline">
                Cancel
              </Button>
              <Button colorScheme="red" onClick={onConfirmGoBack} ml={3}>
                Go Back
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <SurveyFloatingTrigger /> {/* Add SurveyFloatingTrigger here */}
    </>
  );
};

export default BottomBar;
