// FooterBar.js
import React from 'react';
import { Box, Flex, Link, Text } from '@chakra-ui/react';
import packageJson from '../../package.json';

const FooterBar = () => {
  return (
    <Box
      width="100%"
      bg="white"
      p={2}
      height="40px"
    >
      <Flex justify="space-between" align="center" maxW="1200px" mx="auto" px={4}>
        <Text fontSize="sm">© 2024 UXOVision.com - Version: {packageJson.version}</Text>
        <Flex align="center" gap={4}>
          <Link href="/privacy-policy" fontSize="sm">Privacy Policy</Link>
          <Link href="/terms-and-conditions" fontSize="sm">Terms and Conditions</Link>
          <Link href="https://weareindy.com/app/shared/forms/666f3e1c4229f56368e160d6/u6vlHwKayMX77gBV3h3ntKx4mOPqfOBEqF1fBo7Qaz2EQUZkkuCqA2cMIGu41MuW/" target="_blank" fontSize="sm">
            Send Feedback
          </Link>
        </Flex>
      </Flex>
    </Box>
  );
};

export default FooterBar;
